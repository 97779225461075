<template>
	<div class="container-fluid">
		<div class="row py-3">
			<div
				class="col-3 col-md-2 col-lg-2 mb-3"
				v-for="division in divisions"
				:key="division.id"
			>
				<div class="tournament text-center">
					<router-link
						:to="{
							name: 'DivisionHome',
							params: {
								lang: lang,
								console: console,
								divisionSlug: division.slug,
							},
						}"
					>
						<img :src="division.image" :alt="division.name" class="img-fluid" />
					</router-link>
					<h2 class="title">{{ division.name }}</h2>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col-12 text-center">
				<h5 class="mb-3">{{ $t('notices') }}</h5>
			</div>
			<div
				class="col-6 col-sm-4 col-md-3 col-lg-3 mb-3"
				v-for="notice in notices"
				:key="notice.id"
			>
				<div class="card w-100">
					<router-link
						:to="{
							name: 'Notice',
							params: { lang: lang, console: console, slug: notice.slug },
						}"
					>
						<img :src="notice.image" class="card-img-top" :alt="notice.title" />
					</router-link>
					<div class="card-body">
						<h5 class="card-title mb-0 text-center title">
							{{ notice.title }}
						</h5>
					</div>
					<div class="card-footer d-flex justify-content-between">
						<small class="text-muted">{{ notice.created_at }}</small>
						<small class="text-muted"
							>{{ notice.reviews }} {{ $t('comments') }}</small
						>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
	data() {
		return {
			slug: '',
			divisions: [],
			notices: [],
		}
	},
	computed: {
		...mapGetters(['loading', 'lang', 'console', 'tournamentFront']),
	},
	mounted() {
		this.slug = this.$route.params.tournamentSlug
		this.$store.dispatch('SET_MENU_PRIMARY_TOURNAMENT', 'home')
		this.fetchData()
	},
	methods: {
		fetchData() {
			const lang = this.$route.params.lang
			const console = this.$route.params.console
			const slug = this.$route.params.tournamentSlug
			const path = `${lang}/console/${console}/tournament/${slug}/home`
			this.$axios.get(path).then((response) => {
				const data = response.data
				this.divisions = data.divisions
				this.notices = data.notices
			})
		},
	},
}
</script>
