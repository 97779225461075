var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"row py-3"},_vm._l((_vm.divisions),function(division){return _c('div',{key:division.id,staticClass:"col-3 col-md-2 col-lg-2 mb-3"},[_c('div',{staticClass:"tournament text-center"},[_c('router-link',{attrs:{"to":{
						name: 'DivisionHome',
						params: {
							lang: _vm.lang,
							console: _vm.console,
							divisionSlug: division.slug,
						},
					}}},[_c('img',{staticClass:"img-fluid",attrs:{"src":division.image,"alt":division.name}})]),_c('h2',{staticClass:"title"},[_vm._v(_vm._s(division.name))])],1)])}),0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 text-center"},[_c('h5',{staticClass:"mb-3"},[_vm._v(_vm._s(_vm.$t('notices')))])]),_vm._l((_vm.notices),function(notice){return _c('div',{key:notice.id,staticClass:"col-6 col-sm-4 col-md-3 col-lg-3 mb-3"},[_c('div',{staticClass:"card w-100"},[_c('router-link',{attrs:{"to":{
						name: 'Notice',
						params: { lang: _vm.lang, console: _vm.console, slug: notice.slug },
					}}},[_c('img',{staticClass:"card-img-top",attrs:{"src":notice.image,"alt":notice.title}})]),_c('div',{staticClass:"card-body"},[_c('h5',{staticClass:"card-title mb-0 text-center title"},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(notice.title)+"\n\t\t\t\t\t")])]),_c('div',{staticClass:"card-footer d-flex justify-content-between"},[_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(notice.created_at))]),_c('small',{staticClass:"text-muted"},[_vm._v(_vm._s(notice.reviews)+" "+_vm._s(_vm.$t('comments')))])])],1)])})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }